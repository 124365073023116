import React from 'react';

import css from './SectionNavBar.module.css';

//map nav bar items
const navItems = [
  { name: 'GPUs', link: '/s?pub_categoryLevel1=pc_parts_and_components&pub_categoryLevel2=gpu_id' },
  { name: 'CPUs', link: '/s?pub_categoryLevel1=pc_parts_and_components&pub_categoryLevel2=cpu_id' },
  { name: 'Gaming PCs', link: '/s?pub_categoryLevel1=full_systems&pub_categoryLevel2=gaming_pcs' },
  {
    name: 'Motherboards',
    link: '/s?pub_categoryLevel1=pc_parts_and_components&pub_categoryLevel2=motherboards_id',
  },
  { name: 'Peripherals', link: '/s?pub_categoryLevel1=peripherals_id' },
  { name: 'VR', link: '/s?pub_categoryLevel1=peripherals_id&pub_categoryLevel2=vr_id' },
];

// Section component that's able to show article content
// The article content is mainly supposed to be inside a block
const SectionNavBar = () => {
  return (
    <nav className={css.userContainer}>
      <div className={css.navBar}>
        {navItems.map(item => (
          <a className={css.navItem} key={item.name} href={`${item.link}`}>
            {item.name}
          </a>
        ))}
      </div>
    </nav>
  );
};

SectionNavBar.defaultProps = {};

SectionNavBar.propTypes = {};

export default SectionNavBar;
