import React from 'react';
import { func, node, object, shape, string } from 'prop-types';
import classNames from 'classnames';

import Field, { hasDataInFields } from '../../Field';
import BlockContainer from '../BlockContainer';
import css from './BlockGridCard.module.css';
import Wave1Svg from '../../../../assets/waves/Wave1.svg';
import Wave2Svg from '../../../../assets/waves/Wave2.svg';
import SystemPng from '../../../../assets/System.png';
import Gpu4080Png from '../../../../assets/geforce-rtx-4090.png';
import Ps5Png from '../../../../assets/PS52.png';
import PcificLogo from '../../../../assets/pcific_dark_with_icon.svg';

const FieldMedia = props => {
  const { className, media, sizes, options } = props;

  const hasMediaField = hasDataInFields([media], options);
  return hasMediaField ? (
    <div className={classNames(className, css.media)}>
      <Field data={media} sizes={sizes} options={options} />
    </div>
  ) : null;
};

const ListingCard = props => {
  const { condition, price, title, image, isSecondCard } = props;
  const cardContainerClasses = isSecondCard ? css.cardContainerSecond : css.cardWrapper;

  return (
    <div className={cardContainerClasses}>
      <div className={css.topGradientLine}></div>
      <div className={css.bottomGradientLine}></div>
      <div className={isSecondCard ? css.cardImageContainer : css.cardSecondImageContainer}>
        <div className={isSecondCard ? css.cardImageWrapper : css.cardImageWrapperSecond}>
          <img src={image} className={css.cardImage} alt="gpu" />
          {/* <Image
            src={`/images/${card.image}`}
            className={styles.cardImage}
            alt="gpu"
            fill={true}
            sizes="(max-width: 200px) 100vw, 200px"
          /> */}
        </div>
      </div>
      <div className={css.cardContent}>
        <div className={isSecondCard ? css.cardHeader : css.cardHeaderSecond}>
          <div className={css.cardHeaderLeft}>
            <div className={css.logoContainer}>
              <img src={PcificLogo} className={css.logoImage} alt="pcific-logo" />
            </div>
            <span className={css.brandName}>PCIFIC</span>
          </div>
          <span className={css.conditionBadge}>{condition}</span>
        </div>
        {isSecondCard ? (
          <>
            <div className={css.cardTitle}>
              <span className={css.titleText}>{title}</span>
            </div>
            <div className={css.cardFooter}>
              <p className={css.priceText}>{price}</p>
              <button className={css.addToCartButton}>
                <span>Add To Cart</span>
              </button>
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
};

const BlockDefault = props => {
  const {
    blockId,
    className,
    rootClassName,
    textClassName,
    ctaButtonClass,
    title,
    text,
    callToAction,
    media,
    responsiveImageSizes,
    options,
  } = props;

  // Apply different styles based on block ID
  let blockClass = '';
  switch (blockId) {
    case 'grid-block-1':
      blockClass = css.gridBlock1;
      break;
    case 'grid-block-2':
      blockClass = css.gridBlock2;
      break;
    case 'grid-block-3':
      blockClass = css.gridBlock3;
      break;
    default:
      blockClass = '';
  }
  const classes = classNames(rootClassName || className, blockClass, css.wrapper);
  const hasTextComponentFields = hasDataInFields([title, text, callToAction], options);

  const cards = [
    {
      id: 'card-2',
      title: 'Playstation 5',
      price: '£500',
      image: Ps5Png,
      condition: 'New',
    },
    {
      id: 'card-1',
      title: 'Gaming PC System',
      price: '£1,200',
      image: SystemPng,
      condition: 'New',
    },
    {
      id: 'card-3',
      title: 'Nvidia RTX 4080',
      price: '£800',
      image: Gpu4080Png,
      condition: 'New',
    },
  ];

  const renderImage = () => {
    if (blockId === 'grid-block-1') {
      return (
        <>
          <img alt="wave" src={Wave1Svg} className={css.wave} />
          <img alt="wave" src={Wave2Svg} className={css.wave} />
        </>
      );
    }
  };

  const renderListingCards = () => {
    if (blockId === 'grid-block-1') {
      return cards.map((card, index) => {
        const isSecondCard = index === 1; // Check if the current index is 1 (second card)
        const isLastCard = index === cards.length - 1; // Check if the current index is the last card
        let ListingCardPos = css.listingCardPos;
        if (isSecondCard) {
          ListingCardPos = css.listingCardPosSecond;
        } else if (isLastCard) {
          ListingCardPos = css.listingCardPosLast;
        }

        return (
          <div className={ListingCardPos}>
            <ListingCard
              key={card.id}
              title={card.title}
              price={card.price}
              image={card.image}
              condition={card.condition}
              isSecondCard={isSecondCard}
            />
          </div>
        );
      });
    }
  };

  return (
    <BlockContainer id={blockId} className={classes}>
      <div className={classes}>
        <FieldMedia media={media} sizes={responsiveImageSizes} options={options} />
        {renderImage()}
        {blockId === 'grid-block-1' ? (
          <div className={css.listingContainer}>{renderListingCards()}</div>
        ) : null}

        {hasTextComponentFields ? (
          <div className={classNames(textClassName, css.text)}>
            <Field data={title} options={options} />
            <Field data={text} options={options} />
            <Field data={callToAction} className={ctaButtonClass} options={options} />
          </div>
        ) : null}
      </div>
    </BlockContainer>
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

BlockDefault.defaultProps = {
  className: null,
  rootClassName: null,
  mediaClassName: null,
  textClassName: null,
  ctaButtonClass: null,
  title: null,
  text: null,
  callToAction: null,
  media: null,
  responsiveImageSizes: null,
  options: null,
};

BlockDefault.propTypes = {
  blockId: string.isRequired,
  className: string,
  rootClassName: string,
  mediaClassName: string,
  textClassName: string,
  ctaButtonClass: string,
  title: object,
  text: object,
  callToAction: object,
  media: object,
  responsiveImageSizes: string,
  options: propTypeOption,
};

export default BlockDefault;
